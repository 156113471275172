import { useContext } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPages } from '../../../menu';

const DashboardAside = () => {
	const navigate = useNavigate();

	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { asideProfileStatus, setAsideProfileStatus } = useContext(ThemeContext);

	const { darkModeStatus } = useDarkMode();

	const is_premium = useSelector((state) => state.me.user?.is_premium);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={{ ...dashboardPages }} id='aside-dashboard' />

				{asideStatus && !is_premium && (
					<Card className='m-3 '>
						<CardBody className='pt-0'>
							<img srcSet={HandWebp} src={Hand} alt='Hand' width={130} height={130} />
							<p
								className={classNames('h4', {
									'text-dark': !darkModeStatus,
									'text-light': darkModeStatus,
								})}>
								Unlock the full potential of stock trading analysis!
							</p>
							<Button
								color='success'
								isLight
								icon='Upgrade'
								className='w-100'
								onClick={() => navigate(`/pricing`)}>
								Pricing
							</Button>
						</CardBody>
					</Card>
				)}
			</AsideBody>
			<AsideFoot>
				{/*<Navigation
					menu={{
						...{
							forum: {
								id: 'forum',
								text: 'Forums',
								path: '/forums/',
								icon: 'Forum',
							},
						},
					}}
					id='aside-dashboard-bottom'
				/>*/}
				<User
					asideProfileStatus={asideProfileStatus}
					setAsideProfileStatus={setAsideProfileStatus}
				/>
			</AsideFoot>
		</Aside>
	);
};

export default DashboardAside;
