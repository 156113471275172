import { combineReducers } from 'redux';

import adminDataReducer from './features/adminDataSlice';
import alertReducer from './features/alertSlice';
import authReducer from './features/authSlice';
import broadcastReducer from './features/broadcastSlice';
import darkpoolReducer from './features/darkpoolSlice';
import exposureReducer from './features/exposureSlice';
import flowReducer from './features/flowSlice';
import forumCategoriesReducer from './features/forumCategoriesSlice';
import forumTopicsReducer from './features/forumTopicsSlice';
import meReducer from './features/meSlice';
import volumeReducer from './features/volumeSlice';

const rootReducer = combineReducers({
	auth: authReducer,
	me: meReducer,
	forumTopics: forumTopicsReducer,
	forumCategories: forumCategoriesReducer,
	broadcast: broadcastReducer,
	alert: alertReducer,
	darkpool: darkpoolReducer,
	exposure: exposureReducer,
	volume: volumeReducer,
	flow: flowReducer,
	adminData: adminDataReducer,
});

export default rootReducer;
